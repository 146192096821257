import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { getInterviews } from '../services/api';
import InterviewCard from '../components/cards/InterviewCard';
import LoadingSpinner from '../components/common/LoadingSpinner';
import ErrorAlert from '../components/common/ErrorAlert';
import { Link } from 'react-router-dom';

interface Interview {
  _id: string;
  title: string;
  interviewee: string;
  role: string;
  slug: string;
  company: string;
  topic: string;
  excerpt: string;
  imageUrl: string;
  duration: number;
  tags: string[];
  scheduledDate: string;
}

const InterviewsPage: React.FC = () => {
  const [allInterviews, setAllInterviews] = useState<Interview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        setLoading(true);
        const response = await getInterviews({});
        
        // Ensure we're getting an array of interviews
        const validInterviews = Array.isArray(response.data.interviews) ? response.data.interviews : [];
        
        setAllInterviews(validInterviews);
        setError(null);
      } catch (err) {
        setError('Failed to fetch interviews. Please try again later.');
        console.error('Error fetching interviews:', err);
        setAllInterviews([]); // Reset to empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchInterviews();
  }, []); // Only fetch once when component mounts

  // Get unique tags from all interviews
  const allTags = useMemo(() => {
    // Create a Set of all tags and convert back to array
    const tagSet = new Set<string>();
    
    allInterviews.forEach(interview => {
      if (Array.isArray(interview.tags)) {
        interview.tags.forEach(tag => tagSet.add(tag));
      }
    });
    
    return Array.from(tagSet).sort();
  }, [allInterviews]);

  // Filter interviews based on search term and selected tag
  const filteredInterviews = useMemo(() => {
    return allInterviews.filter(interview => {
      const searchFields = [
        interview.title,
        interview.interviewee,
        interview.company,
        interview.topic,
        interview.excerpt
      ];

      const matchesSearch = !searchTerm || searchFields.some(field => 
        field && field.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const matchesTag = !selectedTag || 
        (Array.isArray(interview.tags) && interview.tags.includes(selectedTag));

      return matchesSearch && matchesTag;
    });
  }, [allInterviews, searchTerm, selectedTag]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <ErrorAlert message={error} />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Tech Interviews - Insights from Industry Leaders</title>
        <meta name="description" content="Explore in-depth interviews with technology leaders, innovators, and experts sharing their insights, experiences, and vision for the future." />
        <meta name="keywords" content="tech interviews, industry leaders, innovation insights, tech experts, leadership interviews" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Tech Interviews - Insights from Industry Leaders" />
        <meta property="og:description" content="Explore in-depth interviews with technology leaders and innovators." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Tech Innovation Interviews
          </h1>
          <p className="text-gray-600 mb-6">
            Discover insights from industry leaders and innovators shaping the future of technology.
          </p>
          
          {/* Search and Filter Controls */}
          <div className="flex flex-col md:flex-row gap-4 mb-6">
            <input
              type="text"
              placeholder="Search interviews..."
              className="flex-grow p-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              value={selectedTag || ''}
              onChange={(e) => setSelectedTag(e.target.value || null)}
            >
              <option value="">All Topics</option>
              {allTags.map(tag => (
                <option key={tag} value={tag}>{tag}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Interview Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredInterviews.map((interview) => (
            <Link 
              key={interview._id}
              to={`/interviews/${interview.slug}`}
              className="block"
            >
              <InterviewCard
                interview={interview}
              />
            </Link>
          ))}
        </div>

        {filteredInterviews.length === 0 && (
          <div className="text-center py-8">
            <p className="text-gray-600">
              No interviews found matching your search criteria.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default InterviewsPage;
