import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ArrowLeftIcon, StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CommentSection from '../components/comments/CommentSection';
import { getReview, getComments } from '../services/api';

interface Review {
  _id: string;
  title: string;
  content: string;
  summary: string;
  rating: number;
  productName: string;
  company: string;
  imageUrl: string;
  author: {
    _id: string;
    name: string;
  };
  pros: string[];
  cons: string[];
  createdAt?: string;
  publishedDate?: string;
  category?: string;
}

interface Comment {
  _id: string;
  author: {
    _id: string;
    name: string;
  };
  content: string;
  createdAt: string;
  replies?: Comment[];
}

const ReviewDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [review, setReview] = useState<Review | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReview = async () => {
      if (!id) {
        setError('Review ID is missing');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await getReview(id);
        
        if (!response.data) {
          setError('Review not found');
          return;
        }

        setReview(response.data.review);
        
        // Fetch comments
        const commentsResponse = await getComments({
          contentType: 'review',
          contentId: id
        });
        setComments(commentsResponse.data || []);
      } catch (err) {
        console.error('Error fetching review:', err);
        setError('Failed to load review');
      } finally {
        setLoading(false);
      }
    };

    fetchReview();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center p-4">
        <div className="max-w-4xl w-full">
          <div className="bg-white rounded-2xl shadow-lg p-8 animate-pulse">
            <div className="h-64 bg-gray-200 rounded-lg mb-6"></div>
            <div className="h-8 bg-gray-200 rounded w-3/4 mb-6"></div>
            <div className="flex justify-between items-center mb-8">
              <div className="space-y-3 w-1/2">
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/3"></div>
              </div>
              <div className="flex space-x-1">
                {[1, 2, 3, 4, 5].map((i) => (
                  <div key={i} className="w-6 h-6 bg-gray-200 rounded-full"></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !review) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center p-4">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            {error || 'Review not found'}
          </h2>
          <Link to="/reviews" className="text-indigo-600 hover:text-indigo-800">
            Back to Reviews
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      {review && (
        <Helmet>
          <title>{`${review.productName} Review - ${review.title}`}</title>
          <meta name="description" content={review.summary} />
          <meta name="keywords" content={`${review.productName}, ${review.company}, product review, tech review, ${review.category}`} />
          <link rel="canonical" href={window.location.href} />
          <meta property="og:title" content={`${review.productName} Review - ${review.title}`} />
          <meta property="og:description" content={review.summary} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={review.imageUrl} />
          <meta property="article:published_time" content={review.publishedDate} />
          <meta property="article:author" content={review.author.name} />
          <meta property="article:section" content={review.category} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${review.productName} Review`} />
          <meta name="twitter:description" content={review.summary} />
          <meta name="twitter:image" content={review.imageUrl} />
        </Helmet>
      )}
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <Link
            to="/reviews"
            className="inline-flex items-center text-indigo-600 hover:text-indigo-800 mb-8"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            Back to Reviews
          </Link>

          <article className="bg-white rounded-2xl shadow-lg overflow-hidden">
            <img
              src={review.imageUrl}
              alt={review.productName}
              className="w-full h-96 object-cover"
            />
            
            <div className="p-8">
              <header className="mb-8">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h1 className="text-3xl font-bold text-gray-900 mb-2">{review.title}</h1>
                    <p className="text-lg text-gray-600">{review.productName} by {review.company}</p>
                  </div>
                  <div className="flex items-center gap-1">
                    {[...Array(5)].map((_, index) => (
                      index < review.rating ? (
                        <StarIconSolid key={index} className="h-6 w-6 text-yellow-400" />
                      ) : (
                        <StarIcon key={index} className="h-6 w-6 text-gray-300" />
                      )
                    ))}
                  </div>
                </div>
                
                <div className="flex items-center text-sm text-gray-500">
                  <span>By {review.author.name}</span>
                  {review.createdAt && (
                    <>
                      <span className="mx-2">•</span>
                      <span>{new Date(review.createdAt).toLocaleDateString()}</span>
                    </>
                  )}
                </div>
              </header>

              <div className="prose max-w-none mb-8">
                <div className="bg-gray-50 rounded-lg p-6 mb-8">
                  <p className="text-lg text-gray-700 italic mb-6">{review.summary}</p>
                  
                  <div className="grid md:grid-cols-2 gap-6">
                    <div>
                      <h3 className="text-lg font-semibold text-green-600 mb-3">Pros</h3>
                      <ul className="space-y-2">
                        {review.pros.map((pro, index) => (
                          <li key={index} className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                            {pro}
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div>
                      <h3 className="text-lg font-semibold text-red-600 mb-3">Cons</h3>
                      <ul className="space-y-2">
                        {review.cons.map((con, index) => (
                          <li key={index} className="flex items-center text-gray-700">
                            <svg className="h-5 w-5 text-red-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            {con}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    code({ className, children }) {
                      const match = /language-(\w+)/.exec(className || '');
                      return match ? (
                        <SyntaxHighlighter
                          style={atomDark as any}
                          language={match[1]}
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className}>
                          {children}
                        </code>
                      );
                    },
                  }}
                >
                  {review.content}
                </ReactMarkdown>
              </div>

              <CommentSection
                comments={comments}
                contentType="review"
                contentId={review._id}
              />
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default ReviewDetailPage;
