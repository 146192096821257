import React from 'react';
import { Link } from 'react-router-dom';

interface Interview {
  _id: string;
  title: string;
  interviewee: string;
  slug: string;
  role: string;
  company: string;
  topic: string;
  excerpt: string;
  imageUrl: string;
  duration: number;
  tags: string[];
  scheduledDate: string;
}

interface InterviewCardProps {
  interview: Interview;
}

const InterviewCard: React.FC<InterviewCardProps> = ({ interview }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <Link to={`/interviews/${interview.slug}`}>
        <div className="relative h-48">
          <img
            src={interview.imageUrl}
            alt={interview.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
            <h3 className="text-white text-xl font-semibold line-clamp-2">
              {interview.title}
            </h3>
          </div>
        </div>
        <div className="p-4">
          <div className="mb-3">
            <p className="text-gray-600 text-sm mb-1">
              {interview.interviewee} • {interview.role}
            </p>
            <p className="text-gray-500 text-sm">{interview.company}</p>
          </div>
          <p className="text-gray-700 mb-4 line-clamp-2">{interview.excerpt}</p>
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap gap-2">
              {interview.tags.slice(0, 2).map((tag, index) => (
                <span
                  key={index}
                  className="px-2 py-1 bg-gray-100 text-gray-600 text-xs rounded-full"
                >
                  {tag}
                </span>
              ))}
            </div>
            <div className="text-gray-500 text-sm">
              {interview.duration} min read
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default InterviewCard;
