import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import BlogCard from '../components/cards/BlogCard';
import { getBlogPosts } from '../services/api';

interface BlogPost {
  _id: string;
  title: string;
  slug: string;
  author: {
    _id: string;
    name: string;
  };
  date: string;
  readTime: string;
  excerpt: string;
  imageUrl: string;
  tags: string[];
  status: string;
}

const BlogPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await getBlogPosts({
          page: parseInt(searchParams.get('page') || '1'),
          limit: 10,
          tag: searchParams.get('tag') || undefined,
          search: searchParams.get('search') || undefined
        });
        setPosts(response.data.posts);
        setError(null);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError('Failed to load blog posts');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [searchParams]);

  const featuredPost = posts.find(post => post.status === 'featured');
  const otherPosts = posts.filter(post => post.status !== 'featured');

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Tech Blog - Latest Technology Insights and Stories</title>
        <meta name="description" content="Explore our tech blog for in-depth articles, insights, and stories about the latest in technology, innovation, and digital transformation." />
        <meta name="keywords" content="tech blog, technology articles, innovation stories, digital transformation" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Tech Blog - Latest Technology Insights" />
        <meta property="og:description" content="Explore in-depth articles and stories about technology, innovation, and digital transformation." />
        <meta property="og:type" content="blog" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Blog</h1>

        {featuredPost && (
          <div className="mb-12">
            <Link to={`/blog/${featuredPost.slug}`} className="block">
              <BlogCard
                {...featuredPost}
                id={featuredPost._id}
                author={featuredPost.author.name}
                status={featuredPost.status}
              />
            </Link>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {otherPosts.map((post) => (
            <Link key={post._id} to={`/blog/${post.slug}`} className="block">
              <BlogCard
                {...post}
                id={post._id}
                author={post.author.name}
              />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogPage;
