import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import ArticleCard from '../components/cards/ArticleCard';
import { getArticles } from '../services/api';

interface Article {
  _id: string;
  title: string;
  description: string;
  imageUrl: string;
  category: string;
  slug: string;
  status: string;
  createdAt: string;
}

const categories = ['All', 'Artificial Intelligence', 'Quantum Computing', 'Robotics', 'Biotech', 'Green Tech', 'Cybersecurity'];

const ArticlesPage: React.FC = () => {
  const [allArticles, setAllArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await getArticles({});
        
        if (response.status === 'success' && response.data?.articles) {
          const publishedArticles = response.data.articles.filter((article: Article) => article.status === 'published');
          setAllArticles(publishedArticles);
          setError(null);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        console.error('Error fetching articles:', err);
        setError('Failed to load articles. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []); // Only fetch once when component mounts

  // Filter articles based on search query and category
  const filteredArticles = useMemo(() => {
    return allArticles.filter(article => {
      const matchesCategory = selectedCategory === 'All' || article.category === selectedCategory;
      const matchesSearch = !searchQuery || 
        article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (article.description && article.description?.toLowerCase().includes(searchQuery.toLowerCase()));
      return matchesCategory && matchesSearch;
    });
  }, [allArticles, selectedCategory, searchQuery]);

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <div className="text-red-600 mb-4">{error}</div>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Tech Articles - Latest Technology News and Analysis</title>
        <meta name="description" content="Stay informed with our comprehensive collection of technology articles covering the latest news, trends, and in-depth analysis in the tech industry." />
        <meta name="keywords" content="tech articles, technology news, tech analysis, innovation news" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Tech Articles - Latest Technology News" />
        <meta property="og:description" content="Stay informed with the latest technology news, trends, and in-depth analysis." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Latest Articles</h1>
        
        {/* Search Bar */}
        <div className="mb-8">
          <input
            type="text"
            placeholder="Search articles..."
            value={searchQuery}
            onChange={handleSearch}
            className="w-full md:w-96 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        {/* Category Filter */}
        <div className="flex flex-wrap gap-2 mb-8">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryChange(category)}
              className={`px-4 py-2 rounded-full text-sm font-medium ${
                selectedCategory === category
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Articles Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredArticles.map((article) => (
            <Link 
              key={article._id}
              to={`/articles/${article.slug}`}
              className="block"
            >
              <ArticleCard
                article={article}
              />
            </Link>
          ))}
        </div>

        {filteredArticles.length === 0 && (
          <div className="text-center py-10">
            <p className="text-gray-600">No articles found matching your criteria.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ArticlesPage;
