import React, { useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { formatDistanceToNow, format } from 'date-fns';

interface ArticleCardProps {
  article: {
    _id: string;
    title: string;
    description: string;
    imageUrl: string;
    category: string;
    createdAt: string;
  };
  onClick?: () => void;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article, onClick }) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/articles/${article._id}`);
    }
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    if (diffInMinutes < 60 * 3) { // 3 hours grace of now
      return 'Now';
    } else if (diffInMinutes < 1440) { // 60 minutes * 24 hours
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours} Hour${hours > 1 ? 's' : ''} ago`;
    } else if (diffInMinutes < 41760) { // 60 minutes * 24 hours * 29 days
      const days = Math.floor(diffInMinutes / 1440);
      return `${days} Day${days > 1 ? 's' : ''} ago`;
    } else {
      return format(date, 'MMM d');
    }
  };

  return (
    <div 
      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer"
      onClick={handleClick}
    >
      <div className="relative h-48">
        <img
          src={article.imageUrl}
          alt={article.title}
          className="w-full h-full object-cover"
          onError={handleImageError}
        />
        <div className="absolute top-4 left-4">
          <span className="px-3 py-1 bg-indigo-600 text-white text-sm font-semibold rounded-full">
            {article.category}
          </span>
        </div>
      </div>
      <div className="p-6">
      <div className="text-gray-500 text-sm mt-2">
          {formatDate(article.createdAt)}
        </div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{article.title}</h3>
        <ReactMarkdown className="text-gray-600 mb-4 line-clamp-2">{article.description}</ReactMarkdown>
        <div className="flex items-center text-indigo-600 font-medium">
          Read More
          <ArrowRightIcon className="w-4 h-4 ml-2" />
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
