import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getArticle, toggleArticleLike } from '../services/api';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Comments from '../components/Comments';
import { CalendarIcon, TagIcon } from '@heroicons/react/24/outline';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

interface Article {
  _id: string;
  title: string;
  content: string;
  description: string;
  imageUrl: string;
  category: string;
  createdAt: string;
  updatedAt: string;
  likes?: number;
  author: {
    _id: string;
    name: string;
  };
}

const ArticleDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [imageError, setImageError] = useState(false);
  const [likes, setLikes] = useState<number>(0);
  const [hasLiked, setHasLiked] = useState<boolean>(false);
  const [isUpdatingLike, setIsUpdatingLike] = useState(false);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        const response = await getArticle(id!);
        if (response.status === 'success' && response.data?.article) {
          setArticle(response.data.article);
          setLikes(response.data.article.likes || 0);
          const likedArticles = JSON.parse(localStorage.getItem('likedArticles') || '[]');
          setHasLiked(likedArticles.includes(response.data.article._id));
          setError(null);
        } else {
          throw new Error('Article not found');
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        setError('Article not found. Please check the URL and try again.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchArticle();
    }
  }, [id]);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleToggleLike = async () => {
    if (isUpdatingLike || !article) return;

    try {
      setIsUpdatingLike(true);
      const action = hasLiked ? 'unlike' : 'like';
      const response = await toggleArticleLike(article._id, action);
      
      if (response.status === 'success') {
        setLikes(response.data.likes);
        setHasLiked(!hasLiked);

        // Update localStorage
        const likedArticles = JSON.parse(localStorage.getItem('likedArticles') || '[]');
        if (action === 'like') {
          localStorage.setItem('likedArticles', JSON.stringify([...likedArticles, article._id]));
        } else {
          localStorage.setItem('likedArticles', JSON.stringify(likedArticles.filter((id: string) => id !== article._id)));
        }
        
        toast.success(hasLiked ? 'Article unliked' : 'Thanks for liking this article!');
      }
    } catch (error) {
      console.error('Error updating likes:', error);
      toast.error('Failed to update like status. Please try again later.');
      
      // Rollback optimistic update if needed
      setHasLiked(!hasLiked);
      setLikes(prev => prev + (hasLiked ? 1 : -1));
    } finally {
      setIsUpdatingLike(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="min-h-screen bg-gray-50 px-4 py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900">{error}</h2>
          <div className="mt-6">
            <button
              onClick={() => navigate('/articles')}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <ArrowLeftIcon className="w-5 h-5 mr-2" />
              Back to Articles
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {article && (
        <Helmet>
          <title>{article.title} - Tech Innovation News</title>
          <meta name="description" content={article.description} />
          <meta name="keywords" content={`${article.category}, tech news, innovation, ${article.title.toLowerCase()}`} />
          <link rel="canonical" href={window.location.href} />
          <meta property="og:title" content={article.title} />
          <meta property="og:description" content={article.description} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={article.imageUrl} />
          <meta property="article:published_time" content={article.createdAt} />
          <meta property="article:modified_time" content={article.updatedAt} />
          <meta property="article:section" content={article.category} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={article.title} />
          <meta name="twitter:description" content={article.description} />
          <meta name="twitter:image" content={article.imageUrl} />
        </Helmet>
      )}
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <button
            onClick={() => navigate('/articles')}
            className="inline-flex items-center mb-8 text-indigo-600 hover:text-indigo-700"
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2" />
            Back to Articles
          </button>

          <article className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="relative h-96">
              <img
                src={imageError ? 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80' : article.imageUrl}
                alt={article.title}
                className="w-full h-full object-cover"
                onError={handleImageError}
              />
              <div className="absolute top-4 left-4">
                <span className="px-4 py-2 bg-indigo-600 text-white text-sm font-semibold rounded-full">
                  {article.category}
                </span>
              </div>
            </div>
            
            <div className="max-w-4xl mx-auto px-6 py-12">
              <header className="mb-12">
                <h1 className="text-4xl font-bold text-gray-900 mb-6">{article.title}</h1>
                <div className="flex items-center space-x-6 text-gray-600">
                  <div className="flex items-center">
                    <CalendarIcon className="w-5 h-5 mr-2" />
                    <time dateTime={article.createdAt}>
                      {new Date(article.createdAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </time>
                  </div>
                  <div className="flex items-center">
                    <TagIcon className="w-5 h-5 mr-2" />
                    <span>{article.category}</span>
                  </div>
                  {article.author && (
                    <div className="flex items-center">
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      <span>By {article.author.name}</span>
                    </div>
                  )}
                </div>
              </header>

              <div className="prose prose-lg max-w-none">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ node, className, children, ...props }: any) {
                    const match = /language-(\w+)/.exec(className || '');
                    const isInline = !match;
                    return !isInline ? (
                      <SyntaxHighlighter
                        {...props}
                        style={atomDark as any}
                        language={match![1]}
                        PreTag="div"
                      >
                        {String(children).replace(/\n$/, '')}
                      </SyntaxHighlighter>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  }
                }}
              >
                  {article.content}
                </ReactMarkdown>
              </div>

              <div className="mt-12 pt-8 border-t border-gray-200">
                <h2 className="text-2xl font-semibold text-gray-900 mb-6">Share this article</h2>
                <div className="flex items-center">
                  <div className="flex space-x-4">
                    <button 
                      onClick={() => {
                        const url = encodeURIComponent(window.location.href);
                        const text = encodeURIComponent(article.title);
                        window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`, '_blank', 'width=600,height=400');
                      }}
                      className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                      </svg>
                      Share on X
                    </button>
                    <button 
                      onClick={() => {
                        const url = encodeURIComponent(window.location.href);
                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank', 'width=600,height=400');
                      }}
                      className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                    >
                      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                      </svg>
                      Share on Facebook
                    </button>
                    <button 
                      onClick={() => {
                        const url = encodeURIComponent(window.location.href);
                        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, '_blank', 'width=600,height=400');
                      }}
                      className="flex items-center px-4 py-2 bg-blue-400 text-white rounded-lg hover:bg-blue-500 transition-colors"
                    >
                      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                      </svg>
                      Share on LinkedIn
                    </button>
                  </div>
                  <div className="ml-auto">
                    <button 
                      onClick={handleToggleLike}
                      disabled={isUpdatingLike}
                      className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
                        isUpdatingLike
                          ? 'bg-gray-100 text-gray-500'
                          : hasLiked
                          ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200'
                          : 'bg-yellow-50 text-yellow-600 hover:bg-yellow-100'
                      }`}
                    >
                      {isUpdatingLike ? (
                        <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      ) : (
                        <span className="text-xl mr-2" role="img" aria-label="thinking face">
                          🤔
                        </span>
                      )}
                      {likes} {likes === 1 ? 'Hmmm' : 'Hmmm\'s'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <Comments contentType="article" contentId={article._id} />
        </div>
      </div>
    </>
  );
};

export default ArticleDetailPage;
