import axios from 'axios';

const API_BASE_URL = '/api';

// Create axios instance with base configuration
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to include auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Auth API
export const login = async (credentials: { email: string; password: string }) => {
  const response = await api.post('/auth/login', credentials);
  return response.data;
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const register = async (userData: {
  name: string;
  email: string;
  password: string;
}) => {
  const response = await api.post('/auth/register', userData);
  return response.data;
};

// Articles API
export interface Article {
  _id: string;
  title: string;
  content: string;
  summary: string;
  author: {
    _id: string;
    name: string;
    id: string;
  };
  status: 'draft' | 'published' | 'featured';
  tags: string[];
  category: string;
  imageUrl?: string;
  views: number;
  createdAt: string;
  updatedAt: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  keywords: string[];
  canonicalUrl?: string;
}

export interface CreateArticleDto {
  title: string;
  content: string;
  summary: string;
  category: string;
  tags: string[];
  imageUrl?: string;
  status: 'draft' | 'published' | 'featured';
  metaTitle: string;
  metaDescription: string;
  keywords: string[];
  canonicalUrl?: string;
  author: string;  // This should be the author's ID
}

export const getArticles = async (params?: {
  page?: number;
  limit?: number;
  category?: string;
  search?: string;
  status?: string;
}) => {
  const response = await api.get('/articles', { params: { ...params, status: 'all' } });
  return response.data;
};

export const getArticle = async (id: string) => {
  const response = await api.get(`/articles/${id}`);
  return response.data;
};

export const getFeaturedArticles = async () => {
  const response = await api.get('/articles', { 
    params: { 
      // status: 'featured',
      limit: 9 // Limit to 9 featured articles for the homepage
    } 
  });
  return response.data;
};

export const createArticle = async (articleData: CreateArticleDto) => {
  const response = await api.post('/articles', articleData);
  return response.data;
};

export const updateArticle = async (id: string, articleData: Partial<Article>) => {
  const response = await api.put(`/articles/${id}`, articleData);
  return response.data;
};

export const deleteArticle = async (id: string) => {
  const response = await api.delete(`/articles/${id}`);
  return response.data;
};

// Interviews API
export const getInterviews = async (params?: {
  page?: number;
  limit?: number;
  category?: string;
  search?: string;
}) => {
  const response = await api.get('/interviews', { params });
  return response.data;
};

export const getInterview = async (id: string) => {
  const response = await api.get(`/interviews/${id}`);
  return response.data;
};

export const createInterview = async (interviewData: {
  title: string;
  content: string;
  interviewee: string;
  company: string;
  position: string;
  duration: number;
}) => {
  const response = await api.post('/interviews', interviewData);
  return response.data;
};

export const updateInterview = async (id: string, interviewData: {
  title: string;
  content: string;
  interviewee: string;
  company: string;
  position: string;
  duration: number;
}) => {
  const response = await api.put(`/interviews/${id}`, interviewData);
  return response.data;
};

export const deleteInterview = async (id: string) => {
  const response = await api.delete(`/interviews/${id}`);
  return response.data;
};

export const getAllInterviews = async () => {
  const response = await api.get('/interviews');
  return response.data;
};

// Reviews API
export const getReviews = async (params?: {
  page?: number;
  limit?: number;
  category?: string;
  search?: string;
}) => {
  const response = await api.get('/reviews', { params });
  return response.data;
};

export const getReview = async (id: string) => {
  const response = await api.get(`/reviews/${id}`);
  return response.data;
};

export const createReview = async (reviewData: {
  title: string;
  content: string;
  rating: number;
  product: string;
  company: string;
}) => {
  const response = await api.post('/reviews', reviewData);
  return response.data;
};

export const updateReview = async (id: string, reviewData: {
  title: string;
  content: string;
  rating: number;
  product: string;
  company: string;
}) => {
  const response = await api.put(`/reviews/${id}`, reviewData);
  return response.data;
};

export const deleteReview = async (id: string) => {
  const response = await api.delete(`/reviews/${id}`);
  return response.data;
};

// Comments API
export const getComments = async (params: {
  contentType: 'article' | 'interview' | 'review' | 'technology' | 'blog';
  contentId: string;
}) => {
  const response = await api.get(`/comments/${params.contentType}/${params.contentId}`);
  return response.data;
};

export const createComment = async (commentData: {
  content: string;
  contentType: 'article' | 'interview' | 'review' | 'technology' | 'blog';
  contentId: string;
  parentId?: string;
}) => {
  const response = await api.post('/comments', commentData);
  return response.data;
};

export const updateComment = async (id: string, commentData: {
  content: string;
  contentType: 'article' | 'interview' | 'review' | 'technology' | 'blog';
  contentId: string;
  parentId?: string;
}) => {
  const response = await api.put(`/comments/${id}`, commentData);
  return response.data;
};

export const deleteComment = async (id: string) => {
  const response = await api.delete(`/comments/${id}`);
  return response.data;
};

// Blog API
export const getBlogPosts = async (params?: {
  page?: number;
  limit?: number;
  status?: boolean;
  tag?: string;
  search?: string;
}) => {
  const response = await api.get('/blog/public', { params });
  return response.data;
};

export const getFeaturedBlogPosts = async () => {
  const response = await api.get('/blog/status');
  return response.data;
};

export const getBlogPost = async (id: string) => {
  const response = await api.get(`/blog/${id}`);
  return response.data;
};

export const createBlogPost = async (blogData: {
  title: string;
  content: string;
  excerpt: string;
  readTime: string;
  tags: string[];
  imageUrl: string;
  status?: boolean;
}) => {
  const response = await api.post('/blog', blogData);
  return response.data;
};

export const updateBlogPost = async (id: string, blogData: {
  title?: string;
  content?: string;
  excerpt?: string;
  readTime?: string;
  tags?: string[];
  imageUrl?: string;
  status?: boolean;
}) => {
  const response = await api.put(`/blog/${id}`, blogData);
  return response.data;
};

export const deleteBlogPost = async (id: string) => {
  const response = await api.delete(`/blog/${id}`);
  return response.data;
};

export const toggleBlogPostStatus = async (id: string) => {
  const response = await api.patch(`/blog/${id}/status`);
  return response.data;
};

// Blog endpoints
export const getBlogById = (id: string) => api.get(`/blog/${id}`);
export const getAllBlogs = () => api.get('/blog');
export const createBlog = (blogData: any) => api.post('/blog', blogData);
export const updateBlog = (id: string, blogData: any) => api.put(`/blog/${id}`, blogData);
export const deleteBlog = (id: string) => api.delete(`/blog/${id}`);

// Search API
export const searchContent = async (query: string) => {
  const response = await api.get('/search', { params: { q: query } });
  return response.data;
};

export const getUsers = async () => {
  const response = await api.get('/users');
  return response.data;
};

export const getCurrentUser = async () => {
  const response = await api.get('/users/me');
  return response.data;
};

export const updateCurrentUser = async (userData: { name?: string; email?: string }) => {
  const response = await api.patch('/users/me', userData);
  return response.data;
};

export const updateUserRole = async (userId: string, role: 'user' | 'admin') => {
  const response = await api.patch(`/users/${userId}/role`, { role });
  return response.data;
};

export const deleteUser = async (userId: string) => {
  const response = await api.delete(`/users/${userId}`);
  return response.data;
};

export const setUserPassword = async (userId: string, password: string) => {
  const response = await api.patch(`/users/${userId}/password`, { password });
  return response.data;
};

// Add these interfaces if not already present
export interface User {
  _id: string;
  name: string;
  email: string;
  role: string;
}

export interface CreateUserDto {
  name: string;
  email: string;
  password: string;
  role: string;
}

export const createUser = async (userData: CreateUserDto) => {
  const response = await api.post('/users', userData);
  return response.data;
};

export const updateUser = async (id: string, userData: Partial<CreateUserDto>) => {
  const response = await api.put(`/users/${id}`, userData);
  return response.data;
};

export const getUserById = async (id: string) => {
  const response = await api.get(`/users/${id}`);
  return response.data;
};

// Image Upload API
export const uploadImage = async (imageFile: File) => {
  const formData = new FormData();
  formData.append('image', imageFile);

  const response = await api.post('/images/upload-image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  });

  return response.data.imageUrl;
};

interface LikeResponse {
  status: string;
  data: {
    likes: number;
  };
}

export const toggleArticleLike = async (articleId: string, action: 'like' | 'unlike'): Promise<LikeResponse> => {
  const response = await api.post(`/articles/${articleId}/like`, 
    { action }
  );

  return response.data;
};

export default api;
