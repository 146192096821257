import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getInterview } from '../services/api';
import { CalendarIcon, ClockIcon, UserIcon } from '@heroicons/react/24/outline';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Helmet } from 'react-helmet-async';

interface Interview {
  _id: string;
  title: string;
  interviewee: string;
  role: string;
  slug: string;
  company: string;
  content: string;
  scheduledDate: string;
  duration: number;
  imageUrl: string;
  tags: string[];
  topic: string;
  excerpt: string;
  summary: string;
  date: string;
}

const InterviewDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [interview, setInterview] = useState<Interview | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInterview = async () => {
      try {
        setIsLoading(true);
        const response = await getInterview(id!);
        await setInterview(response.data.interview);
        setError(null);
      } catch (err) {
        setError('Failed to fetch interview');
        console.error('Error fetching interview:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInterview();
  }, [id]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (error || !interview) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500">{error || 'Interview not found'}</div>
      </div>
    );
  }

  return (
    <>
      {interview && (
        <Helmet>
          <title>{`${interview.interviewee} Interview - ${interview.title}`}</title>
          <meta name="description" content={interview.summary} />
          <meta name="keywords" content={`${interview.interviewee}, ${interview.company}, ${interview.tags.join(', ')}, tech interview`} />
          <link rel="canonical" href={window.location.href} />
          <meta property="og:title" content={`Interview with ${interview.interviewee} - ${interview.title}`} />
          <meta property="og:description" content={interview.summary} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={interview.imageUrl} />
          <meta property="article:published_time" content={interview.date} />
          <meta property="article:tag" content={interview.tags.join(',')} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`Interview with ${interview.interviewee}`} />
          <meta name="twitter:description" content={interview.summary} />
          <meta name="twitter:image" content={interview.imageUrl} />
        </Helmet>
      )}
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="relative h-96">
              <img
                src={interview.imageUrl}
                alt={interview.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-4 left-4">
                <span className="px-4 py-2 bg-indigo-600 text-white text-sm font-semibold rounded-full">
                  {interview.topic}
                </span>
              </div>
            </div>
            <div className="max-w-4xl mx-auto px-6 py-12">
              <header className="mb-12">
                <h1 className="text-4xl font-bold text-gray-900 mb-6">{interview.title}</h1>
                <div className="flex items-center space-x-6 text-gray-600">
                  <div className="flex items-center">
                    <CalendarIcon className="w-5 h-5 mr-2" />
                    <time dateTime={interview.scheduledDate}>
                      {new Date(interview.scheduledDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </time>
                  </div>
                  <div className="flex items-center">
                    <UserIcon className="w-5 h-5 mr-2" />
                    <span>{interview.interviewee}</span>
                  </div>
                </div>
              </header>
              <div className="prose prose-lg max-w-none">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    code({ node, className, children, ...props }: any) {
                      const match = /language-(\w+)/.exec(className || '');
                      const isInline = !match;
                      return !isInline ? (
                        <SyntaxHighlighter
                          {...props}
                          style={atomDark as any}
                          language={match![1]}
                          PreTag="div"
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    }
                  }}
                >
                  {interview.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewDetailPage;
