import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ArticleCard from '../components/cards/ArticleCard';
import { getFeaturedArticles } from '../services/api';

interface Article {
  _id: string;
  title: string;
  description: string;
  imageUrl: string;
  category: string;
  slug: string;
  status: string;
  createdAt: string;
}

const HomePage: React.FC = () => {
  const [featuredArticles, setFeaturedArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFeaturedArticles = async () => {
      try {
        setLoading(true);
        const response = await getFeaturedArticles();
        if (response.status === 'success' && response.data?.articles) {
          setFeaturedArticles(response.data.articles);
          setError(null);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        console.error('Error fetching featured articles:', err);
        setError('Failed to load featured articles');
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedArticles();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Tech Innovation News - Latest Technology Insights and Innovations</title>
        <meta name="description" content="Discover the latest innovations and insights in technology. Stay updated with cutting-edge tech news, analysis, and breakthrough discoveries." />
        <meta name="keywords" content="tech news, innovation, technology insights, tech discoveries" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Tech Innovation News - Latest Technology Insights" />
        <meta property="og:description" content="Discover the latest innovations and insights in technology. Stay updated with cutting-edge tech news and analysis." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className="min-h-screen bg-gray-50">
        {/* Hero Section */}
        <section 
          className="relative bg-white"
          aria-labelledby="hero-heading"
        >
          <div 
            className="absolute inset-0 z-0"
            style={{
              backgroundImage: 'url("/xutro-bg.webp")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity: '0.15'
            }}
            role="presentation"
            aria-hidden="true"
          ></div>
          <div className="relative z-10 max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 
                id="hero-heading"
                className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl"
              >
                Tech Innovation News
              </h1>
              <p className="max-w-xl mt-5 mx-auto text-xl text-gray-600">
                Discover the latest innovations and insights in technology
              </p>
            </div>
          </div>
        </section>

        {/* Featured Articles Section */}
        <section 
          className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8"
          aria-labelledby="featured-heading"
        >
          <h2 
            id="featured-heading"
            className="text-3xl font-bold text-gray-900 mb-8"
          >
            Featured Articles
          </h2>
          {error ? (
            <div className="text-center py-10" role="alert">
              <p className="text-red-600">{error}</p>
            </div>
          ) : (
            <div 
              className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
              role="feed"
              aria-label="Featured articles"
            >
              {featuredArticles.map((article) => (
                <Link 
                  key={article._id}
                  to={`/articles/${article.slug}`}
                  className="block"
                >
                  <ArticleCard
                    article={article}
                  />
                </Link>
              ))}
            </div>
          )}
          {featuredArticles.length === 0 && !error && (
            <div className="text-center py-10" role="status">
              <p className="text-gray-600">No featured articles available</p>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default HomePage;
