import React, { useState, useEffect } from 'react';
import { getUsers, uploadImage } from '../../services/api';
import { User } from '../../types/user';

interface ArticleFormProps {
  article?: {
    _id: string;
    title: string;
    content: string;
    summary: string;
    category: string;
    tags: string[];
    imageUrl?: string;
    status: 'draft' | 'published' | 'featured';
    metaTitle: string;
    metaDescription: string;
    keywords: string[];
    canonicalUrl?: string;
    author: {
      _id: string;
      name: string;
      id: string;
    };
  };
  onSubmit: (data: any) => Promise<void>;
  onCancel: () => void;
}

const ArticleForm: React.FC<ArticleFormProps> = ({ article, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    title: article?.title || '',
    content: article?.content || '',
    summary: article?.summary || '',
    category: article?.category || '',
    tags: article?.tags || [],
    imageUrl: article?.imageUrl || '',
    status: article?.status || 'draft',
    metaTitle: article?.metaTitle || '',
    metaDescription: article?.metaDescription || '',
    keywords: article?.keywords || [],
    canonicalUrl: article?.canonicalUrl || '',
    author: article?.author?._id || '',
  });
  const [tagsInput, setTagsInput] = useState(article?.tags.join(', ') || '');
  const [keywordsInput, setKeywordsInput] = useState(article?.keywords?.join(', ') || '');
  const [users, setUsers] = useState<User[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [charCount, setCharCount] = useState({
    metaTitle: formData.metaTitle.length,
    metaDescription: formData.metaDescription.length,
  });

  useEffect(() => {
    setCharCount({
      metaTitle: formData.metaTitle.length,
      metaDescription: formData.metaDescription.length,
    });
  }, [formData.metaTitle, formData.metaDescription]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response.data.users);
      } catch (err) {
        console.error('Failed to fetch users:', err);
        setError('Failed to load users');
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      setError(null);
      const processedData = {
        ...formData,
        tags: tagsInput.split(',').map(tag => tag.trim()).filter(Boolean),
        keywords: keywordsInput.split(',').map(keyword => keyword.trim()).filter(Boolean),
      };
      await onSubmit(processedData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save article');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagsInput(e.target.value);
  };

  const handleKeywordsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeywordsInput(e.target.value);
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      try {
        const imageUrl = await uploadImage(e.target.files[0]);
        setFormData((prevFormData) => ({ ...prevFormData, imageUrl }));
      } catch (error) {
        console.error('Error uploading image:', error);
        setError('Failed to upload image. Please try again.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="text-sm text-red-700">{error}</div>
        </div>
      )}

      {/* Basic Information Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              id="title"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.title}
              onChange={e => setFormData(prev => ({ ...prev, title: e.target.value }))}
            />
          </div>

          <div>
            <label htmlFor="author" className="block text-sm font-medium text-gray-700">
              Author
            </label>
            <select
              id="author"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.author}
              onChange={e => setFormData(prev => ({ ...prev, author: e.target.value }))}
            >
              <option value="">Select an author</option>
              {users.map(user => (
                <option key={user._id} value={user._id}>{user.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="summary" className="block text-sm font-medium text-gray-700">
              Summary (max 200 characters)
            </label>
            <textarea
              id="summary"
              rows={2}
              required
              maxLength={200}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.summary}
              onChange={e => setFormData(prev => ({ ...prev, summary: e.target.value }))}
            />
            <p className="mt-1 text-sm text-gray-500">{formData.summary.length}/200 characters</p>
          </div>

          <div>
            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
              Content
            </label>
            <textarea
              id="content"
              rows={10}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.content}
              onChange={e => setFormData(prev => ({ ...prev, content: e.target.value }))}
            />
          </div>
        </div>
      </div>

      {/* SEO Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">SEO Settings</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="metaTitle" className="block text-sm font-medium text-gray-700">
              Meta Title (max 60 characters)
            </label>
            <input
              type="text"
              id="metaTitle"
              maxLength={60}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.metaTitle}
              onChange={e => setFormData(prev => ({ ...prev, metaTitle: e.target.value }))}
              placeholder="Enter SEO-friendly title"
            />
            <p className="mt-1 text-sm text-gray-500">{charCount.metaTitle}/60 characters</p>
          </div>

          <div>
            <label htmlFor="metaDescription" className="block text-sm font-medium text-gray-700">
              Meta Description (max 160 characters)
            </label>
            <textarea
              id="metaDescription"
              rows={3}
              maxLength={160}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.metaDescription}
              onChange={e => setFormData(prev => ({ ...prev, metaDescription: e.target.value }))}
              placeholder="Enter compelling meta description for search results"
            />
            <p className="mt-1 text-sm text-gray-500">{charCount.metaDescription}/160 characters</p>
          </div>

          <div>
            <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">
              Keywords (comma-separated)
            </label>
            <input
              type="text"
              id="keywords"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={keywordsInput}
              onChange={handleKeywordsChange}
              placeholder="Enter SEO keywords separated by commas"
            />
          </div>

          <div>
            <label htmlFor="canonicalUrl" className="block text-sm font-medium text-gray-700">
              Canonical URL (optional)
            </label>
            <input
              type="url"
              id="canonicalUrl"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.canonicalUrl}
              onChange={e => setFormData(prev => ({ ...prev, canonicalUrl: e.target.value }))}
              placeholder="https://example.com/original-article"
            />
          </div>
        </div>
      </div>

      {/* Category and Tags Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Categories and Tags</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
              Category
            </label>
            <select
              id="category"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={formData.category}
              onChange={e => setFormData(prev => ({ ...prev, category: e.target.value }))}
            >
              <option value="">Select a category</option>
              <option value="Artificial Intelligence">Artificial Intelligence</option>
              <option value="Quantum Computing">Quantum Computing</option>
              <option value="Robotics">Robotics</option>
              <option value="Biotech">Biotech</option>
              <option value="Green Tech">Green Tech</option>
              <option value="Web Development">Web Development</option>
              <option value="Crypto">Crypto</option>
              <option value="Cybersecurity">Cybersecurity</option>
              <option value="Data Science">Data Science</option>
            </select>
          </div>

          <div>
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
              Tags (comma-separated)
            </label>
            <input
              type="text"
              id="tags"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={tagsInput}
              onChange={handleTagsChange}
              placeholder="Enter tags separated by commas"
            />
          </div>
        </div>
      </div>

      {/* Media Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Media</h3>
        <div>
          <label className="block text-sm font-medium text-gray-700">Featured Image URL</label>
          <input
            type="text"
            value={formData.imageUrl}
            onChange={(e) => setFormData({ ...formData, imageUrl: e.target.value })}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="mt-2"
          />
        </div>
      </div>

      {/* Publishing Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Publishing</h3>
        <div>
          <label htmlFor="status" className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            id="status"
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={formData.status}
            onChange={e => setFormData(prev => ({ ...prev, status: e.target.value as 'draft' | 'published' }))}
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
          </select>
        </div>
      </div>

      {/* Form Actions */}
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  );
};

export default ArticleForm;
