import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createComment } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

interface Comment {
  _id: string;
  author: {
    _id: string;
    name: string;
  };
  content: string;
  createdAt: string;
  replies?: Comment[];
}

interface CommentSectionProps {
  contentType: 'article' | 'interview' | 'review' | 'technology' | 'blog';
  contentId: string;
  comments?: Comment[];
  onCommentAdded?: (newComment: Comment) => void;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  contentType,
  contentId,
  comments = [],
  onCommentAdded
}) => {
  const [newComment, setNewComment] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      await createComment({
        content: newComment,
        contentType,
        contentId
      });
      setNewComment('');
      setError(null);
      if (onCommentAdded) {
        const newCommentObj = {
          _id: Math.random().toString(),
          author: {
            _id: Math.random().toString(),
            name: 'Author Name'
          },
          content: newComment,
          createdAt: new Date().toISOString()
        };
        onCommentAdded(newCommentObj);
      }
    } catch (err) {
      setError('Failed to post comment. Please try again.');
    }
  };

  return (
    <div className="mt-8">
      <h3 className="text-lg font-semibold mb-4">Comments</h3>
      <form onSubmit={handleSubmit} className="mb-6">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
          rows={3}
          placeholder="Write a comment..."
        />
        {error && <p className="mt-2 text-red-600 text-sm">{error}</p>}
        <button
          type="submit"
          className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Post Comment
        </button>
      </form>
      {comments.length > 0 ? (
        <div className="space-y-6">
          {comments.map((comment) => (
            <div key={comment._id} className="bg-white p-4 rounded-lg shadow-sm">
              <div className="flex items-center space-x-2 mb-2">
                <span className="font-medium">{comment.author.name}</span>
                <span className="text-gray-500 text-sm">
                  {new Date(comment.createdAt).toLocaleDateString()}
                </span>
              </div>
              <p className="text-gray-700">{comment.content}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center">No comments yet. Be the first to comment!</p>
      )}
    </div>
  );
};

export default CommentSection;
