import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarIcon, ClockIcon, TagIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

interface BlogCardProps {
  id: string;
  slug: string;
  title: string;
  author: string;
  date: string;
  readTime: string;
  excerpt: string;
  imageUrl: string;
  tags: string[];
  status?: string;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

  if (diffInMinutes < 60 * 3) { // 3 hours grace of now
    return 'Now';
  } else if (diffInMinutes < 1440) { // 60 minutes * 24 hours
    const hours = Math.floor(diffInMinutes / 60);
    return `${hours} Hour${hours > 1 ? 's' : ''} ago`;
  } else if (diffInMinutes < 41760) { // 60 minutes * 24 hours * 29 days
    const days = Math.floor(diffInMinutes / 1440);
    return `${days} Day${days > 1 ? 's' : ''} ago`;
  } else {
    return format(date, 'MMM d');
  }
};

const BlogCard: React.FC<BlogCardProps> = ({
  id,
  slug,
  title,
  author,
  date,
  readTime,
  excerpt,
  imageUrl,
  tags,
  status = ''
}) => {
  const navigate = useNavigate();
  const formattedDate = formatDate(date);

  const handleClick = () => {
    navigate(`/blog/${slug}`);
  };

  if (status === 'featured') {
    return (
      <div 
        className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer lg:grid lg:grid-cols-2 lg:gap-8"
        onClick={handleClick}
      >
        <div className="relative h-64 lg:h-full">
          <img
            className="w-full h-full object-cover"
            src={imageUrl}
            alt={title}
          />
        </div>
        <div className="p-8">
          <div className="flex flex-wrap gap-2 mb-4">
            {tags.map((tag) => (
              <span
                key={tag}
                className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800"
              >
                <TagIcon className="h-4 w-4 mr-1" />
                {tag}
              </span>
            ))}
          </div>
          <h2 className="text-3xl font-bold text-gray-900 mb-4">{title}</h2>
          <p className="text-gray-600 text-lg mb-6">{excerpt}</p>
          <div className="flex items-center text-sm text-gray-600">
            <span className="font-medium text-gray-900">{author}</span>
            <span className="mx-2">•</span>
            <CalendarIcon className="h-4 w-4 mr-1" />
            <span>{formattedDate}</span>
            <span className="mx-2">•</span>
            <ClockIcon className="h-4 w-4 mr-1" />
            <span>{readTime}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer"
      onClick={handleClick}
    >
      <div className="relative h-48">
        <img
          className="w-full h-full object-cover"
          src={imageUrl}
          alt={title}
        />
      </div>
      <div className="p-6">
        <div className="flex flex-wrap gap-2 mb-3">
          {tags.map((tag) => (
            <span
              key={tag}
              className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
            >
              <TagIcon className="h-3 w-3 mr-1" />
              {tag}
            </span>
          ))}
        </div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600 mb-4 line-clamp-2">{excerpt}</p>
        <div className="flex items-center text-sm text-gray-600">
          <span className="font-medium text-gray-900">{author}</span>
          <span className="mx-2">•</span>
          <CalendarIcon className="h-4 w-4 mr-1" />
          <span>{formattedDate}</span>
          <span className="mx-2">•</span>
          <ClockIcon className="h-4 w-4 mr-1" />
          <span>{readTime}</span>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
