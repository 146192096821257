import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import ArticlesPage from './pages/ArticlesPage';
import ArticleDetailPage from './pages/ArticleDetailPage';
import InterviewsPage from './pages/InterviewsPage';
import InterviewDetailPage from './pages/InterviewDetailPage';
import ReviewsPage from './pages/ReviewsPage';
import ReviewDetailPage from './pages/ReviewDetailPage';
import BlogPage from './pages/BlogPage';
import BlogDetailPage from './pages/BlogDetailPage';
import LoginPage from './pages/LoginPage';
import AdminDashboard from './pages/admin/AdminDashboard';
import ArticleManagement from './pages/admin/ArticleManagement';
import InterviewManagement from './pages/admin/InterviewManagement';
import ReviewManagement from './pages/admin/ReviewManagement';
import CommentManagement from './pages/admin/CommentManagement';
import BlogManagement from './pages/admin/BlogManagement';
import PrivateRoute from './components/auth/PrivateRoute';
import UserManagement from './pages/admin/UserManagement';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <Routes>
            
            {/* Auth Routes */}
            <Route path="/login" element={<LoginPage />} />

            {/* Admin Routes */}
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/articles"
              element={
                <PrivateRoute>
                  <ArticleManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/blogs"
              element={
                <PrivateRoute>
                  <BlogManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/interviews"
              element={
                <PrivateRoute>
                  <InterviewManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/reviews"
              element={
                <PrivateRoute>
                  <ReviewManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/comments"
              element={
                <PrivateRoute>
                  <CommentManagement />
                </PrivateRoute>
              }
            />
            <Route path="/admin/users" element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            } />

            {/* Public Routes */}
            <Route
              path="/*"
              element={
                <div className="min-h-screen bg-gray-50">
                  <Navbar />
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/articles" element={<ArticlesPage />} />
                    <Route path="/articles/:id" element={<ArticleDetailPage />} />
                    <Route path="/interviews" element={<InterviewsPage />} />
                    <Route path="/interviews/:id" element={<InterviewDetailPage />} />
                    <Route path="/reviews" element={<ReviewsPage />} />
                    <Route path="/reviews/:id" element={<ReviewDetailPage />} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/blog/:id" element={<BlogDetailPage />} />
                  </Routes>
                </div>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
